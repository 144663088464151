import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; 
import SliderComponent from "../components/SliderComponent";
import config from "../services/config";
import sanitizeHtml from "sanitize-html";

const DetailPage = () => {
  const ENDPOINT = "announcements";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  
  const apiRequest = async () => {
    const response = await fetch(`${config.API_URL}/${ENDPOINT}/${id}`);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("API request failed");
    }
  };

  const [data, setData] = useState();
  useEffect(() => {
    if (id) {
      apiRequest()
        .then((data) => {
          setData(data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      window.location.href = "/";
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]); 

  const sanitizeOptions = {
    allowedTags: ["b", "i", "em", "strong", "a", "span", "ol", "li", "ul","br","p"],
    allowedAttributes: {
      a: ["href"],
    },
  };

  const sanitizedTitle = sanitizeHtml(data?.content, sanitizeOptions);

  return (
    <div className="w-full h-full mb-28 -mt-12 sm:-mt-10 md:-mt-10 lg:-mt-0 xl:-mt-0 2xl:-mt-0">
      <div className="w-full flex items-center justify-center relative h-[35vh] px-4">
        <div className="h-full w-full absolute about-bg bg-opacity-50 -mt-24 flex flex-col"></div>
        <h1 className="2xl:text-7xl xl:text-6xl lg:text-6xl text-3xl font-extrabold text-center text-components-title">
          {data?.announcementTypeText}
        </h1>
      </div>
      <p className="2xl:my-20 xl:my-20 lg:my-20 mt-14 text-components-title 2xl:text-4xl xl:text-4xl lg:text-4xl text-center font-bold 2xl:px-14 xl:px-14 lg:px-14 px-4 ">
        {data?.title}
      </p>
      <div className="2xl:px-16 xl:px-16 lg:px-16 px-4 py-6 flex justify-center items-center">
        <img
          src={`${config.API_URL}/file/${data?.mainPictureId}`}
          width={250}
          height={250}
          alt="image"
          className="w-8/12 rounded-none"
        />
      </div>
      <div
        className="2xl:my-20 xl:my-20 lg:my-20 mt-4 text-components-title 2xl:text-xl xl:text-xl lg:text-xl text-sm text-center font-medium 2xl:px-14 xl:px-14 lg:px-14 px-4 "
        dangerouslySetInnerHTML={{ __html: sanitizedTitle }}
      />
      <SliderComponent
        detailSlider={true}
        data={data}
        files={true}
        shadowText={"Duyuralar"}
        title={"Proje Duyuraları"}
        description={"Bu kısma Bu kısım ile ilgili örnek metin yazılacaktır. Şuan örnek amaçlı yazılmıştır."}
        closeText={true}
      />
    </div>
  );
};

export default DetailPage;
