import config from "../services/config.js";
import { Link as RouterLink} from "react-router-dom";

export default function SliderItemComponent({ data, closeText, images, detailSlider }) {
  return (
    <>
      {detailSlider !== true ? (
        <RouterLink to={`/detail?id=${data?.id}`}>
          <div className="bg-diagram-box-bg mx-4 rounded-xl sliderItemComponent" style={{minHeight:"22rem"}}>
            <img src={`${config.API_URL}/file/${images}`} className="w-full h-[16rem] object-cover" alt="test" width={400} height={50} />
            {!closeText && <p className="m-6 font-bold text-components-title pb-6 text-center ">{data?.title} </p>}
          </div>
        </RouterLink>
      ) : (
        <div className="">
          <div className="bg-diagram-box-bg mx-4 rounded-xl">
            <img src={`${config.API_URL}/file/${images}`} className="w-full h-[16rem] object-cover" alt="test" width={400} height={50} />
            {!closeText && <p className="m-6 font-bold text-components-title pb-6 text-center ">{data?.title}</p>}
          </div>
        </div>
      )}
    </>
  );
}
