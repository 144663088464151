import DiagramItemComponent from "../components/DiagramItemComponent.js"
export default function DiagramComponent(){
    const data = [
        {
            id:1,
            title:'Bilgilendirme',
            description:'Projenin üreticiler tarafından benimsenmesi ve yaygınlaştırılmasını sağlamak amacıyla üreticilere yönelik bilgilendirme eğitimleri düzenlenmektedir.',
            order:"order-1 2xl:order-1 xl:order-1 lg:order-1"
        },
        {
            id:2,
            title:'Kayıt',
            description:'Bilgilendirmeler sonrası projede yer almak isteyen üreticilerin  “Üretici Bilgi Formlarına” gıda izleme görevlileri tarafından kimlik, iletişim ve diğer bilgileri kaydedilmektedir.',
            order:"order-2 2xl:order-2 xl:order-2 lg:order-2"
        },
        {
            id:3,
            title:'Kontrol',
            description:'Projeye katılım yönünden uygunluk taşıyan üreticiler, üretim yaptıkları arazide ziyaret edilmekte ve arazi kontrolleri sağlanmaktadır.',
            order:"order-4 2xl:order-3 xl:order-3 lg:order-3"
        },
        {
            id:4,
            title:'Gıda Kalite ve Güvenliği',
            description:'Üreticilerin yetiştiricilik yaptığı arazilerden ve pazara getirilen ürünlerden alınan numunelerin, Proje ortağı İstanbul Üniversitesi tarafından “Gıda Kalitesi ve Güvenliği Analizlerini” gerçekleştirilmektedir.',
            order:"order-3 2xl:order-4 xl:order-4 lg:order-4"
        },
        {
            id:5,
            title:'Değerlendirme',
            description:'Yapılan kontroller ve analizlerin değerlendirilmesi sonucunda uygun görülen üreticiler “Topraktan Fileye Gıda İzleme Modeli”nde yer almaya hak kazanmaktadırlar.',
            order:"order-5 2xl:order-8 xl:order-8 lg:order-8"
        },
        {
            id:6,
            title:'Veri Kayıt',
            description:'Hak sahibi çiftçilerin arazi bilgileri, ürün bilgileri, sevkiyat bilgileri, eğitim-sertifika bilgileri, gıda kalite ve güvenliği analiz sonuçları, ürünlere ve arazilere ait yetiştiricilik alanında çekilen fotoğrafları ve diğer bilgiler “Topraktan Fileye” uygulamasına ait veritabanına kaydedilmektedir.',
            order:"order-6 2xl:order-7 xl:order-7 lg:order-7"
        },
        {
            id:7,
            title:'Eğitimler',
            description:'Kayıtlı üreticilere; “Gıda İzleme Sistemi Temel Yeterlilik Eğitimleri” ve “Gıda Kalite ve Gıda Güvenliği Eğitimleri” ana başlıklı dört farklı eğitim “İstanbul Büyükşehir Belediyesi Tarım ve Su Ürünleri Şube Müdürlüğü” ve ”İstanbul Üniversitesi Bitki ve Bitkisel Ürünler Uygulama ve Araştırma Merkezi” tarafından verilmektedir.',
            order:"order-8 2xl:order-6 xl:order-6 lg:order-6"
        },
        {
            id:8,
            title:'Denetim',
            description:'Belirli aralıklarla araziler ve ürünler kontrol edilmekte, bilgilerin güncel kalması sağlanmaktadır. Pazar kurulum günlerinde ise “Gıda İzleme Görevlileri” tezgahları kontrol etmekte ve fiyat ve stok bilgilerini anlık olarak gıda izleme modeline ait platformlar üzerinden tüketicilere sunulmaktadır.',
            order:"order-7 2xl:order-5 xl:order-5 lg:order-5"
        },
        {
            id:9,
            title:'İzlenebilir ve Güvenilir Gıda',
            description:'Topraktan Fileye Gıda İzleme Modeli hakkında detaylı bilgilere, haberlere ve duyurulara  topraktanfileye.istanbul adresi üzerinden; Gıda İzleme Modeline gim.topraktanfileye.istanbul ve “İstanbul Senin” mobil uygulaması üzerinden erişilebilmektedir.',
            order:"order-9 2xl:order-9 xl:order-9 lg:order-9"
        }
    ]
    return(
        <div className="relative overflow-hidden 2xl:mt-32 xl:mt-32 lg:mt-32 mt-6 p-6 pt-10 2xl:pb-0 xl:pb-0 lg:pb-0 mb-0">
           <div className="flex justify-center items-center flex-col 2xl:mb-20 xl:mb-20 lg:mb-20 mb-6">
            <h1 className="text-components-title absolute 2xl:top-0 xl:top-0 lg:top-0 top-4 -z-[1] font-extrabold leading-relaxed 2xl:text-8xl xl:text-8xl lg:text-8xl text-[3rem] 2xl:mt-0 xl:mt-0 lg:mt-0">Diyagram</h1>
            <h1 className="text-components-title relative  font-extrabold leading-relaxed text-center 2xl:text-4xl xl:text-4xl lg:text-4xl text-2xl 2xl:mt-0 xl:mt-0 lg:mt-0">Model Yönetim Diyagramı</h1>
           {/* p'nin içerisine açıklama yazılabilir */}
            <p className=" text-components-title font-bold mt-6 text-center text-sm"></p>
           </div>
           <div className=" grid grid-cols-12 gap-4 2xl:mx-8 xl:mx-8 lg:mx-8 relative  z-10">
            {/* cirle 1 start */}
            <div className="2xl:top-10 xl:top-10 lg:top-10 2xl:-right-72 xl:-right-72 lg:-right-72 top-6 -right-36 circle 
             2xl:w-[400px] xl:w-[400px] lg:w-[400px]  2xl:h-[400px] xl:h-[400px] lg:h-[400px] w-[150px] h-[150px]
             absolute border-[2px] border-orange-500 -z-[1] rounded-full">
                        <div className="circle  2xl:w-[350px] xl:w-[350px] lg:w-[350px]  2xl:h-[350px] xl:h-[350px] lg:h-[350px] w-[125px] h-[125px] absolute top-[50%] left-[50%] border-[2px] border-orange-500 -z-[1] rounded-full">
                            <div className="circle 2xl:w-[300px] xl:w-[300px] lg:w-[300px]  2xl:h-[300px] xl:h-[300px] lg:h-[300px] w-[100px] h-[100px] absolute top-[50%] left-[50%] border-[2px] border-orange-500 -z-[1] rounded-full"></div>
                        </div>
            </div>
            {/* cirle 1 end */}
            {/* cirle 2 start */}
            <div className="2xl:bottom-0 xl:bottom-0 lg:bottom-0 -bottom-32 -left-0 circle 
             2xl:w-[200px] xl:w-[200px] lg:w-[200px]  2xl:h-[200px] xl:h-[200px] lg:h-[200px] w-[150px] h-[150px]
             absolute border-[2px] border-orange-500 -z-[1] rounded-full">
                        <div className="circle  2xl:w-[300px] xl:w-[300px] lg:w-[300px]  2xl:h-[300px] xl:h-[300px] lg:h-[300px] w-[125px] h-[125px] absolute top-[50%] left-[50%] border-[2px] border-orange-500 -z-[1] rounded-full">
                            <div className="circle 2xl:w-[250px] xl:w-[250px] lg:w-[250px]  2xl:h-[250px] xl:h-[250px] lg:h-[250px] w-[100px] h-[100px] absolute top-[50%] left-[50%] border-[2px] border-orange-500 -z-[1] rounded-full"></div>
                        </div>
            </div>
            {/* cirle 2 end */}
           {data.map((item,index) => (<DiagramItemComponent data={item} key={index}/>))}
           </div>
        </div>
    )
}

